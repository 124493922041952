import styled from 'styled-components';
import { respondTo } from '../../../styles/shared/breakpoints';
import { dotsBlue, greyCopy } from '../../../styles/shared/colors';
import PageSection from '../../layout/PageSection';

export const HomeCtaSection = styled(PageSection)`
    padding: 42px 0;
`;

export const CtaContentContainer =  styled.div`
    display: flex;
    flex-direction: column;
    ${respondTo.sm`
        flex-direction: row;
    `}
`;

export const CtaImage = styled.img`
    margin: 0 auto 24px;
    width: 150px;
    height: 150px;
    padding: 0;
    ${respondTo.sm`
        margin-right: 40px;
        margin-bottom: 0;
        padding-left: 20px;
    `}
    ${respondTo.md`
        padding-left: 0px;
    `}
`;

export const CtaContentHeader = styled.p`
    color: ${greyCopy};
    font-size: 17px;
    line-height: 26px;
    margin-bottom: 19px;
`;

export const CtaContent = styled.div`
    color: ${dotsBlue};
    h2{
        font-family: 'noto-serif';
        font-size: 27px;
        line-height: 38px;
        margin-bottom: 20px;
        ${respondTo.sm`
            margin-top: -8px;
        `}
    }

    p{
        color: ${greyCopy};
        font-size: 17px;
        line-height: 26px;
        margin-bottom: 30px;
    }

    ul {
        color: ${greyCopy};
        font-size: 17px;
        line-height: 26px;
        list-style: disc;
        margin-left: 16px;
        margin-bottom: 19px;
        ${respondTo.sm`
            margin-left: 26px;
        `}
        li {
            padding-left: 12px;
        }
    }

    a {
        width: 100%;
        img {
            height: 10px;
        }
        ${respondTo.sm`
            width: 35%;
        `}
    }

`;