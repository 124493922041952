import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const dot = keyframes`
  0% { 
    opacity: 0;
  }
  50% { 
    opacity: 1;
  }
  50.01% { 
    opacity: 1;
  }
  100% { 
    opacity: 0;
  }
`;

export const slideIn = keyframes`
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0;
    opacity: 1;
  }
`;

export const pulse = keyframes`
  0% {
    transform: scale( 0.1, 0.1);
    opacity: 0
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale( 1.5, 1.5);
    opactiy: 0;
  }
`;

export const expandpulse = keyframes`
  0% {
    transform: scale( 0.3, 0.3);
  }
  50% {
    transform: scale( 1.2, 1.2);
  }
  100% {
    transform: scale( 1, 1);
  }
`;