import React from 'react';
import HomeCtaSection from '../components/elements/HomeCtaSection';
import HomeHero from '../components/elements/HomeHero';
import TalkToDoctorSection from '../components/elements/TalkToDoctorSection';
import homeCopy from '../copy/home';
import * as s from '../styles/pages/home';
import PageSeo from '../components/elements/Seo/PageSeo';

const IndexPage = () => {
  return (
    <s.HomePage>
      <PageSeo pageTitle={ homeCopy.pageSeoTitle } pageDescription={ homeCopy.pageDescription } pageKeywords={ homeCopy.pageKeywords }>
        <script type="application/ld+json">{`"@context":"http://schema.org/","url":"https://www.coulditbevhl.com/","@type":"MedicalWebPage","name":"What is von Hippel-Lindau (VHL) disease?","description":"VHL disease is a rare genetic disorder where cysts and tumors can appear in various parts of the body, such as the kidneys, spinal cord, eyes, adrenal glands, and brain. Because these growths may seem like they’re related to other conditions, many may not connect them to VHL disease."`}</script>
        <script type="application/ld+json">{`"@context":"http://schema.org/","url":"https://www.coulditbevhl.com/","@type":"MedicalAudience","audienceType":"Patient","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."`}</script>
        <link rel="canonical" href="https://www.coulditbevhl.com/"/>
        <link rel="icon" href="favicon.svg" type="image/svg+xml"></link>
      </PageSeo>
      <HomeHero {...homeCopy.hero}></HomeHero>
      {homeCopy.homeCtaSections.map((cta, i) => (
        <HomeCtaSection key={i} {...cta}/>
      ))}
      <TalkToDoctorSection {...homeCopy.talkToDoctor}/>
    </s.HomePage>
  )
}

export default IndexPage;
