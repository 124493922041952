import React from 'react';
import { H2 } from '../../../styles/shared/typography';
import Button from '../Button';
import * as s from './styles';

const HomeCtaSection = ({className, icon, iconAlt, title, content, buttonLabel, buttonLinkTo}) => {
  return (
    <s.HomeCtaSection narrow className={className}>
        <s.CtaContentContainer>
            <s.CtaImage src={icon} alt={iconAlt}/>
            <s.CtaContent>
                <H2>{title}</H2>
                {content.header && 
                    <s.CtaContentHeader><strong>
                        {content.header}
                    </strong></s.CtaContentHeader>}
                {content.header_line2 && 
                    <s.CtaContentHeader><strong>
                        {content.header_line2}
                    </strong></s.CtaContentHeader>}
                {content.list && 
                <ul>
                    {content.list.map((listItem, i) => (
                        <li key={i}>{listItem}</li>
                    ))}
                </ul>}
                {content.para && <p>{content.para}</p>}
                {content.boldFooter && <p><strong>{content.boldFooter}</strong></p>}
                {buttonLabel && <Button icon='arrow-right' to={buttonLinkTo} data-design-category='button_vd'>{buttonLabel}</Button>}
            </s.CtaContent>
        </s.CtaContentContainer>
    </s.HomeCtaSection>
  );
};


export default HomeCtaSection;