import React from 'react';
import * as s from './styles';
import { H1 } from '../../../styles/shared/typography';
import underline from '../../../assets/images/Multicolor_Underline.svg';
import HeroImage from '../HeroImage';

const HomeHero = ({subheader, primaryHeader, className}) => {
  return (
    <s.HomeHero grey narrow className={className}>
      <s.HeroContainer alignCenter gap="32px">
        <s.CopyContainer>
          <p>{subheader}</p>
          <H1>{primaryHeader}</H1>
          <s.HeroUnderline src={underline} aria-hidden />
        </s.CopyContainer>

        <HeroImage />

      </s.HeroContainer>
    </s.HomeHero>
  );
};


export default HomeHero;