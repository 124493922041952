import React from 'react';
import * as s from './styles';
import lightBulb from '../../../assets/images/lightbulb.svg';
import { H2 } from '../../../styles/shared/typography';

const TalkToDoctorSection = ({className, title, subtitle, copyPasteTitle, copyPasteDesc, questions, buttonLabel, mobileImgSrc, desktopImgSrc, imgAlt, copyContent}) => {
    return (
    <s.Wrapper className={className} id='talk-to-doctor-about-VHL'>
        <s.TalkToDoctorContainer shadow='purple'>
                <s.Illustration src={mobileImgSrc} alt={imgAlt} className='mobile-only' aria-hidden/>
                <H2>{title}</H2>
                <s.Subtitle><strong>{subtitle}</strong></s.Subtitle>
                <s.TalkToDoctorColumns>
                    <s.TalkToDoctorColumn1>
                        <s.Illustration src={desktopImgSrc} alt={imgAlt} aria-hidden/>
                        <s.TalkToDoctorIconSection>
                            <s.Icon src={lightBulb} alt='' aria-hidden/>
                            <s.TalkToDoctorIconContent>
                                <p><strong>{copyPasteTitle}</strong></p>
                                <p>{copyPasteDesc}</p>
                            </s.TalkToDoctorIconContent>
                        </s.TalkToDoctorIconSection>
                    </s.TalkToDoctorColumn1>
                    <s.TalkToDoctorColumn2>
                        <ul>
                        {questions.map((question, i) => (
                            <li key={i}>{question}</li>
                        ))}
                        </ul>
                        <s.TalkToDoctorIconSection className='mobile-only'>
                            <s.Icon src={lightBulb} alt='' aria-hidden/>
                            <s.TalkToDoctorIconContent>
                                <p><strong>{copyPasteTitle}</strong></p>
                                <p>{copyPasteDesc}</p>
                            </s.TalkToDoctorIconContent>
                        </s.TalkToDoctorIconSection>
                        <s.CopyButton icon='copy' copyContent={copyContent} data-design-category='button_vd'>{buttonLabel}</s.CopyButton>
                    </s.TalkToDoctorColumn2>
                </s.TalkToDoctorColumns>
        </s.TalkToDoctorContainer>
    </s.Wrapper>
  );
};


export default TalkToDoctorSection;