import styled from 'styled-components';
import {dot, expandpulse, fadeIn, pulse} from '../../../styles/shared/keyframes';
import { respondTo } from '../../../styles/shared/breakpoints';

export const HeroImageContainer = styled.div`
  height: 325px;
  display: flex;
  justify-content: flex-end;
  width: 290px;
  margin-top: 0;
  overflow: hidden;

  ${respondTo.sm`
    margin-top: 25px;
    width: 310px;
  `}

  .dot-1, .dot-2, .dot-3, .dot-4, .dot-5, .dot-6{
    animation: ${dot} 6s ease-in-out infinite;
    opacity: 0;
  }

  .dot-1_2, .dot-2_2, .dot-3_2 {
    animation: ${fadeIn} ease forwards;
    animation-iteration-count: 1;
    opacity: 0;
  }

  .dot-1 {
    animation-delay: .9s;
  }

  .dot-2 {
    animation-delay: 1.8s;
  }

  .dot-3 {
    animation-delay: 2.7s;
  }

  .dot-4 {
    animation-delay: 3.6s;
  }

  .dot-5 {
    animation-delay: 4.5s;
  }

  .dot-6 {
    animation-delay: 5.4s;
  }

  .pulse {
    animation: ${fadeIn} ease-out;
    animation-iteration-count: infinite;
    animation-duration: 1s;
    opacity: 0;
  }

  .expandpulse {
    animation: ${expandpulse} 2s ease-out;
    animation-iteration-count: 1;
  }
`;