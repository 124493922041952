import styled from 'styled-components';
import { respondTo } from '../shared/breakpoints';
import { greyBorder } from '../shared/colors';

export const HomePage = styled.div`
    section:nth-child(2) {
        ${respondTo.sm`
            padding: 48px 0 56px 0;
        `}
        .pageSectionWrapper {
            position: relative;
            &:after {
                content: '';
                height: 2px;
                background-color: ${greyBorder};
                position: absolute;
                bottom: -42px;
                width: calc(100% - 40px);
                left: 20px;
                ${respondTo.sm`
                    width: calc(100% + 30px);
                    left: -15px;
                    bottom: -56px;
                `}
            }
        }
    }

    section:nth-child(3) {
        ${respondTo.sm`
            padding: 54px 0 58px 0;
        `}
        p:first-of-type {
            margin-bottom: 16px;
            ${respondTo.sm`
                margin-bottom: 20px;
            `}
        }
        p:last-of-type {
            margin-bottom: 0px;
        }
    }
`;
