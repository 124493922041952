import styled from 'styled-components';
import { respondTo } from '../../../styles/shared/breakpoints';
import { dotsBlue, dotsPurple } from '../../../styles/shared/colors';
import PageSection from '../../layout/PageSection';
import { fadeIn, rightDirection, slideIn } from '../../../styles/shared/keyframes';

export const HomeHero = styled(PageSection)`
  padding: 30px 0 0 0;
  .pageSectionWrapper {
    padding: 0 20px 0 20px;
    ${respondTo.sm`
      padding: 0;
    `}
  }
  ${respondTo.sm`
    padding: 25px 0 0 0;
  `}
`;

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  ${respondTo.sm`
    flex-direction: row;
  `}
`;


export const CopyContainer = styled.div`
  color: ${dotsBlue};
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  ${respondTo.sm`
      width: calc(100% - 310px);
      text-align: left;
      margin-left: 40px;
  `}
  ${respondTo.md`
      margin-left: 0px;
  `}
  p {
      font-size: 23px;
      line-height: 35px;
      margin-bottom: 8px;
      width: 100%;
      animation: ${fadeIn} 1s linear forwards;
      ${respondTo.sm`
          width: 60%;
      `}
    }
  h1 {
    font-size: 40px;
    line-height: 41px;
    font-family: 'noto-serif';
    opacity: 0;
    animation: ${fadeIn} 2s linear forwards;
    animation-delay: 2s;
    position: relative;
    margin-bottom: 10px;
    width: 100%;
    ${respondTo.sm`
        width: 93%;
        font-size: 42px;
        line-height: 48px;
        margin-bottom: 16px;
    `}
  }
`;

export const HeroUnderline = styled.img`
  width: 100%;
  opacity: 0;
  animation: ${slideIn} 1s linear forwards;
  animation-delay: 3s;
  margin-bottom: 25px;
  ${respondTo.sm`
      width: 92%;
      margin: 0 0 25px 0;
      height: auto;
  `}
`;