import magnifyingGlassIcon from '../assets/icons/icon-magnifying-glass.svg';
import riskIcon from '../assets/icons/icon-risk.svg';
import talkToDoctorMobImg from '../assets/images/illustration.svg';
import talkToDoctorDsktpImg from '../assets/images/doctor-graphic.svg';

const homeCopy = {
  pageSeoTitle: 'Could It Be Von Hippel-Lindau (VHL)? - Official Website',
  pageDescription: 'Learn about a rare disorder known as von Hippel-Lindau (VHL) disease. Talk to your doctor about VHL disease.',
  pageKeywords: 'vhl, von hippel-lindau disease',

  hero: {
    subheader: 'Frequent cysts and tumors in various parts of the body?',
    primaryHeader: 'Could it be VHL disease?',
  },

  homeCtaSections: [
    {
      icon: magnifyingGlassIcon,
      iconAlt: '',
      title: 'What is von Hippel-Lindau (VHL) disease?',
      content: {
        para: 'VHL disease is a rare genetic disorder where cysts and tumors can appear in various parts of the body, such as the kidneys, spinal cord, eyes, adrenal glands, and brain. Because these growths may seem like they’re related to other conditions, many may not connect them to VHL disease.'
      },
      buttonLabel: 'Learn about VHL',
      buttonLinkTo: '/what-is-vhl/',
    },
    {
      icon: riskIcon,
      iconAlt: '',
      title: 'When would you or your doctor suspect VHL disease?',
      content: {
        header: 'VHL disease is a genetic condition. If a parent has it, there’s a 50% chance it may be passed on to each child.',
        header_line2: 'Even without a family history of the disease, your doctor might suspect VHL disease if you have certain cysts or tumors in the following locations:',
        list: [
          'Central nervous system (for example, in the brain or spinal cord)',
          'Kidneys',
          'Retinas',
          'Adrenal gland',
          'Other areas of the body, such as the inner ear',
        ],
        boldFooter: 'Talk with your doctor about VHL disease and ask if genetic testing is right for you.'
      },
    },
  ],

  talkToDoctor: {
    title: 'Talk with your doctor about VHL disease',
    mobileImgSrc: talkToDoctorMobImg,
    desktopImgSrc: talkToDoctorDsktpImg,
    imgAlt: '',
    subtitle: 'Talking with your doctor is the best way to get started if you suspect you may have VHL disease. Here are some questions you can ask during your visit to help guide the conversation.',
    copyPasteTitle: 'Bring these questions to your next doctor\'s visit',
    copyPasteDesc: 'Copy and paste these questions into your email or another document to print or save.',
    questions: [
      'Could my cysts or tumors be related to VHL disease?',
      'How do I know if I have the gene mutation for VHL disease?',
      'Should I consider genetic testing?',
      'If so, will you refer me to a genetic counselor or another specialist?',
      'Should my family members also have genetic testing?',
      'Are there medical centers for people with VHL disease?',
      'What type of doctor should I see?',
      'How often do I need to have checkups?',
      'What’s my risk of developing cancer?',
    ],
    copyContent: 'Talk with your doctor about VHL disease\n• Could my cysts or tumors be related to VHL disease?\n• How do I know if I have the gene mutation for VHL disease?\n• Should I consider genetic testing?\n• If so, will you refer me to a genetic counselor or another specialist?\n• Should my family members also have genetic testing?\n• Are there medical centers for people with VHL disease?\n• What type of doctor should I see?\n• How often do I need to have checkups?\n• What’s my risk of developing cancer?',
    buttonLabel: 'Copy and paste questions',
  }
};


export default homeCopy;
