import React from 'react';
import * as s from './styles';
import Animation from "../../../assets/icons/hero_animation.svg"

const HeroImage = ({className}) => {

  return (
    <s.HeroImageContainer className={className}>
       <iframe title="hero animation" src={Animation} scrolling="no"></iframe>
    </s.HeroImageContainer>
  );
};

export default HeroImage;
