import styled from 'styled-components';
import { respondTo } from '../../../styles/shared/breakpoints';
import {dotsBlue, greyCopy} from '../../../styles/shared/colors';
import { WrapperEl } from '../../../styles/shared/layout';
import ShadowBox from '../ShadowBox';
import Button from '../Button';

export const Subtitle = styled.p`
  color: ${greyCopy};
`;

export const CopyButton = styled(Button)`
 max-width: 340px;
 padding: 12px 40px 14px 10px;
  .copy {
    right: 28px;
  }
  ${respondTo.sm`
    padding: 12px 74px 14px 10px;
  `}
`;

export const Wrapper = styled(WrapperEl)`
    padding: 0 16px;
    margin-bottom: 54px;
    ${respondTo.sm`
        padding: 0 60px;
        margin-bottom: 80px;
    `}
`;

export const TalkToDoctorContainer = styled(ShadowBox)`
  
    color: ${dotsBlue};
    position: relative;
    padding: 26px 0 31px 0;
    width: auto;
    ${respondTo.sm`
        padding: 37px 83px 63px 38px;
        margin: 0 auto;
        width: 100%;
    `}

  h2 {
    font-size: 27px;
    line-height: 38px;
    font-family: 'noto-serif';
    margin-bottom: 8px;
    padding: 0 26px;
    ${respondTo.sm`
       padding: 0;
    `}
  }

  p {
    font-size: 17px;
    line-height: 26px;
    margin-bottom: 24px;
    padding: 0 26px;
    ${respondTo.sm`
       padding: 0;
    `}
  }

  button {
    width: 100%;
    display: block;
    padding-right: 50px;
    img {
      right: 27px;
    }
  }

`;

export const TalkToDoctorColumns = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 52px;
  color: ${greyCopy}
`;

export const TalkToDoctorColumn1 = styled.div`
    flex-direction: column;
    display: none;
    ${respondTo.sm`
        display: flex;
        width: min-content;
    `}
`;

export const Illustration = styled.img`
    width: 327px;
    height: 240px;
    margin-bottom: 23px;
    &.mobile-only {
      height: auto;
      width: 100%;
    ${respondTo.sm`
        display: none;
        margin-bottom: 30px;
    `}
    }
`;

export const Icon = styled.img`
    margin-right: 20px;
    display: flex;
    align-self: baseline;
    margin-top: 5px;
`;

export const TalkToDoctorIconSection = styled.div`
  display: flex;
  padding-left: 29px;
  p{
    margin-bottom: 10px;
  }
  &.mobile-only {
    width: 100%;
    margin-bottom: 24px;
    padding-left: 0px;
    p {
        padding: 0;
    }
    ${respondTo.sm`
        display: none;
    `}
    }
`;

export const TalkToDoctorIconContent = styled.div`
   display: flex;
   flex-direction: column;
   font-size: 17px;
   line-height: 26px;
`;

export const TalkToDoctorColumn2 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 26px;
  align-items: center;
  ${respondTo.sm`
     padding: 0;
     align-items: start;
   `}

   button{
    font-size: 16px;
    line-height: 20px;
   }

  ul {
    font-size: 17px;
    line-height: 26px;
    list-style: disc;
    margin-bottom: 20px;
    margin-left: 20px;
  }

  li {
    margin-bottom: 16px;
    padding-left: 5px;
     &:last-child {
      margin-bottom: 0;
      ${respondTo.sm`
        margin-bottom: 16px;
      `}
     }
  }
`;

